import * as React from "react"

import * as styles from "./history.module.css"
import { i18n } from "../../translate/i18n"

export const VersionHistory = ({ versions }) => (
  <div className={styles.content}>
    <table className={styles.table}>
      <tr className={styles.tr}>
        <th colSpan={3} className={styles.th}>
          {i18n.t("versionHistory")}
        </th>
      </tr>
      {versions.map(item => (
        <tr key={item.id} className={styles.tr}>
          <td className={styles.td}>{item.id}</td>
          <td className={styles.td}>{item.date}</td>
        </tr>
      ))}
    </table>
  </div>
)
