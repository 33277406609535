import * as React from "react"
import { graphql } from "gatsby"
import HtmlParser from "react-html-parser"

import * as styles from "./protection.module.css"
import { i18n } from "../../../translate/i18n"

import Layout from "../../../components/layout"
import Grid from "../../../components/grid"
import {
  Topic1,
  Topic2,
  Topic3,
  Topic4,
  Topic5,
  Topic6,
  Topic7,
  Topic8,
  Topic9,
  Topic10,
  Topic11,
  Topic12,
} from "../../../components/pages/docs/protection/Topics"
import { VersionHistory } from "../../../components/VersionHistory"

function Protection({ location }) {
  return (
    <Layout location={location} headerHome={false}>
      <Grid>
        <h1 className={styles.page_title}>{i18n.t("docs.dataProtection")}</h1>
        <div className={styles.content}>
          <p className={styles.text}>
            {HtmlParser(i18n.t("dataProtection.p1"))}
          </p>
          <p className={styles.text}>{i18n.t("dataProtection.p2")}</p>
          <p className={styles.text}>{i18n.t("dataProtection.p3")}</p>
          <p className={styles.text}>
            {HtmlParser(i18n.t("dataProtection.p4"))}
          </p>
          <div className={styles.topics}>
            <Topic1 />
            <Topic2 />
            <Topic3 />
            <Topic4 />
            <Topic5 />
            <Topic6 />
            <Topic7 />
            <Topic8 />
            <Topic9 />
            <Topic10 />
            <Topic11 />
            <Topic12 />
          </div>
          <VersionHistory
            versions={[
              {
                id: "v1",
                date: `${i18n.t("months.january")}/2023`,
              },
              {
                id: "v2",
                date: `${i18n.t("months.april")}/2023`,
              },
            ]}
          />
        </div>
      </Grid>
    </Layout>
  )
}

export default Protection

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "DD/MM/YYYY")
          title
          description
        }
      }
    }
  }
`
