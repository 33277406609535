import * as React from "react"
import HtmlParser from "react-html-parser"
import { Collapse } from "react-collapse"

import * as styles from "../../../../../pages/docs/protection/protection.module.css"
import { i18n } from "../../../../../translate/i18n"

import ArrowUp from "../../../../../assets/svg/arrow-up.svg"
import ArrowDown from "../../../../../assets/svg/arrow-down.svg"

export const Topic2 = () => {
  const [isOpened, setIsOpened] = React.useState(false)

  return (
    <div
      {...(isOpened && {
        className: styles.container,
      })}
    >
      <button
        onClick={() => setIsOpened(isOpened => !isOpened)}
        className={styles.collapsible}
      >
        <p>{i18n.t("dataProtection.topic2.title")}</p>
        {isOpened ? <ArrowUp /> : <ArrowDown />}
      </button>
      <Collapse isOpened={isOpened}>
        <p className={styles.text}>{i18n.t("dataProtection.topic2.p1")}</p>
        <p className={styles.text}>
          {HtmlParser(i18n.t("dataProtection.topic2.p2"))}
        </p>
        <p className={styles.text}>
          {HtmlParser(i18n.t("dataProtection.topic2.p3"))}
        </p>
        <div className={styles.law}>
          <b className={styles.text}>I)</b>
          <p className={styles.text_indent}>
            {i18n.t("dataProtection.topic2.laws.1")}
          </p>
        </div>
        <div className={styles.law}>
          <b className={styles.text}>II)</b>
          <p className={styles.text_indent}>
            {i18n.t("dataProtection.topic2.laws.2")}
          </p>
        </div>
        <div className={styles.law}>
          <b className={styles.text}>III)</b>
          <p className={styles.text_indent}>
            {i18n.t("dataProtection.topic2.laws.3")}
          </p>
        </div>
        <div className={styles.law}>
          <b className={styles.text}>IV)</b>
          <p className={styles.text_indent}>
            {i18n.t("dataProtection.topic2.laws.4")}
          </p>
        </div>
        <div className={styles.law}>
          <b className={styles.text}>V)</b>
          <p className={styles.text_indent}>
            {i18n.t("dataProtection.topic2.laws.5")}
          </p>
        </div>
        <div className={styles.law}>
          <b className={styles.text}>VI)</b>
          <p className={styles.text_indent}>
            {i18n.t("dataProtection.topic2.laws.6")}
          </p>
        </div>
        <div className={styles.law}>
          <b className={styles.text}>VII)</b>
          <p className={styles.text_indent}>
            {i18n.t("dataProtection.topic2.laws.7")}
          </p>
        </div>
        <div className={styles.law}>
          <b className={styles.text}>VIII)</b>
          <p className={styles.text_indent}>
            {i18n.t("dataProtection.topic2.laws.8")}
          </p>
        </div>
        <div className={styles.law}>
          <b className={styles.text}>IX)</b>
          <p className={styles.text_indent}>
            {i18n.t("dataProtection.topic2.laws.9")}
          </p>
        </div>
        <div className={styles.law}>
          <b className={styles.text}>X)</b>
          <p className={styles.text_indent}>
            {HtmlParser(i18n.t("dataProtection.topic2.laws.10"))}
          </p>
        </div>
        <p className={styles.text}>{i18n.t("dataProtection.topic2.p4")}</p>
      </Collapse>
    </div>
  )
}
