import * as React from "react"
import HtmlParser from "react-html-parser"
import { Collapse } from "react-collapse"

import * as styles from "../../../../../pages/docs/protection/protection.module.css"
import { i18n } from "../../../../../translate/i18n"

import ArrowUp from "../../../../../assets/svg/arrow-up.svg"
import ArrowDown from "../../../../../assets/svg/arrow-down.svg"

export const Topic1 = () => {
  const [isOpened, setIsOpened] = React.useState(false)

  return (
    <div
      {...(isOpened && {
        className: styles.container,
      })}
    >
      <button
        onClick={() => setIsOpened(isOpened => !isOpened)}
        className={styles.collapsible}
      >
        <p>{i18n.t("dataProtection.topic1.title")}</p>
        {isOpened ? <ArrowUp /> : <ArrowDown />}
      </button>
      <Collapse isOpened={isOpened}>
        <p className={styles.text}>
          {HtmlParser(i18n.t("dataProtection.topic1.p1"))}
        </p>
      </Collapse>
    </div>
  )
}
